import React from 'react';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { Constants } from '../core__eventsOutrights-constants';
import PropTypes from 'prop-types';
import Spinner from 'UI/spinner/Spinner';
import { EventsOutrightsTableBody } from './components/EventsTable/elements/EventsOutrightsTableBody';
import { generateNoEventsDataArray } from 'Services/global/core__noEventsArrayGenerator';
import { FSBTheme } from 'Services/core__fsb-theme';
import { EventsAppTitle } from 'UI/navigation/TopLink';
import { getOutrightsUrl } from '../core__eventsOutrights-store';

class EventsOutrightsReactApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appConfig: props.appConfig,
      active: props?.appConfig?.active || !props.appConfig?.eventsHeaderActive,
      title: props.appConfig.title && !props.appConfig.group, //don't show title if part of group
      eventsHeaderActive: props.appConfig?.eventsHeaderActive,
      selectionIds: [],
      theme: props.appConfig?.theme,
      oddsFormat: props?.oddsFormat,
      sortBy: props.appConfig?.sortby,
      sortDir: props.appConfig?.sortdir,
      collapseAll: props.appConfig?.collapseall,
      marketsExpanded: props.appConfig?.marketsexpanded,
      defaultMarketsCount: props.appConfig?.defaultmarketscount,
      category: props.category,
      url: null,
    };

    if (this.state.eventsHeaderActive) {
      PubSub.emit(PubsubEvents.EventHeaderUpdate, {
        appid: this.props?.appConfig?.appid,
        title: this.props?.appConfig?.title,
      });
    }
  }
  /**
   * to hit api for events app overall
   */
  getData = (url, force) => {
    //fetch data if not fetched by server side
    if (!this.props.outrightsData || force) {
      this.setState({ showSpinner: true });
      this.props
        .fetchData(
          url || (this.state.url ?? this.props.url),
          null,
          this.props.appConfig
        )
        .then(() => {
          this.setState({ showSpinner: false });
          //emitter to update category list highlight
          this.emitterForCategoryList();
        });
    }
  };
  componentDidMount() {
    //get data in case if not fetched from ssr
    this.getData();

    // when header tabs are changed
    this.listenerForHeadersApp();

    //listen to betslip change
    this.listenerForBetslipApp();

    //listen to pubsub for odds change
    this.listenerForOddsFormat();

    //listen to category
    this.listenerForCategory();

    this.showOutRightsPuSub = PubSub.listen(
      PubsubEvents.SHOW_OUTRIGHTS,
      data => {
        if (data.group === this.props.appConfig.group) {
          if (data.hide) {
            this.setState({ active: false });
          } else {
            data.url && this.getData(data.url, true);
            this.setState({ active: true });
          }
        }
      }
    );

    //hide apps grouped with events app
    if (this.props.appConfig.group) {
      PubSub.emit(PubsubEvents.EventHeaderUpdate, {
        appid: this.props?.appConfig?.appid,
        hide: true,
      });
    }
  }
  componentWillUnmount() {
    //Unsubscribing from PubSubs
    this.showOutRightsPuSub.unsubscribe();
    this.activeEventsPubsub.unsubscribe();
    this.updatebetSlipPubSub.unsubscribe();
    this.oddsValuePubSub.unsubscribe();
    this.categoryPubSub.unsubscribe();
  }

  emitterForCategoryList = () => {
    const selectedCat = this.props.appConfig?.requestURL.split('/')[2];
    const selectedSubcat = this.props.appConfig?.requestURL.split('/')[3];
    const sportsUrl =
      this.props.appConfig.requestURL?.split(selectedCat).length === 2;
    if (!selectedSubcat && sportsUrl) {
      PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
        category: selectedCat,
      });
    } else {
      PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
        category: selectedCat,
        subcat: selectedSubcat,
        category_label: selectedCat,
        catNameTranslated:
          this.props.outrightsData?.category?.[0]?.name || selectedCat,
        subcat_label: this.props.outrightsData?.category?.[0]?.subcat?.[0]
          ?.name,
      });
    }
  };

  listenerForHeadersApp = () => {
    //else
    this.activeEventsPubsub = PubSub.listen(
      PubsubEvents.EventsAppActive,
      wsData => {
        if (wsData.appid === parseInt(this.state?.appConfig?.appid)) {
          this.setState({ active: true, noData: false }, () => {
            //this.getData(); //for case when events app initially had no data
          });
        } else {
          this.setState({ active: false });
        }
      }
    );
  };

  listenerForBetslipApp = () => {
    this.updatebetSlipPubSub = PubSub.listen(
      PubsubEvents.EventsAppBetslipUpdate,
      wsData => {
        this.setState({ selectionIds: wsData });
      }
    );
  };

  listenerForOddsFormat = () => {
    this.oddsValuePubSub = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      this.setState({ oddsFormat: wsData });
    });
  };

  listenerForCategory = () => {
    this.categoryPubSub = PubSub.listen(
      PubsubEvents.CATEGORY_UPDATE,
      wsData => {
        if (this.props.appConfig.urlamendments) {
          const url = getOutrightsUrl({
            ...this.props.appConfig,
            subcatRef:
              wsData?.[0]?.subcat?.[0]?.ref ?? this.props.appConfig.subcatRef,
            subcategoryRef: wsData?.[0]?.subcat?.[0]?.ref,
          });
          this.setState({ category: wsData, url }, () => this.getData(url));
        }
      }
    );
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps?.outrightsData?.statusCode === 404) {
      PubSub.emit(PubsubEvents.EventHeaderUpdate, {
        appid: nextProps?.appConfig?.appid,
        hide: true,
      });
    }
    return {};
  }
  render() {
    return (
      <FSBTheme theme={this.state.theme}>
        <React.Fragment>
          {!this.state.eventsHeaderActive && (
            <EventsAppTitle active={true} label={this.state.title} />
          )}
          {this.state.showSpinner && this.state.active && (
            <Spinner display={true} width={25} />
          )}

          {this.state.active && (
            <React.Fragment>
              <EventsOutrightsTableBody
                outrightsData={this.props.outrightsData}
                title={this.state.title}
                oddsFormat={this.state.oddsFormat || Constants.FRACTIONAL}
                selectionIds={this.state.selectionIds}
                noEventData={generateNoEventsDataArray(
                  this.props.appConfig?.nodatatitles,
                  this.props.appConfig?.nodatalinks
                )}
                sortBy={this.state.sortBy}
                sortDir={this.state.sortDir}
                collapseAll={this.state.collapseAll}
                marketsExpanded={this.state.marketsExpanded}
                defaultMarketsCount={this.state.defaultMarketsCount}
                appConfig={this.props.appConfig}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </FSBTheme>
    );
  }
}

EventsOutrightsReactApp.propTypes = {
  appConfig: PropTypes.object,
  state: PropTypes.object,
  fetchData: PropTypes.func,
  oddsFormat: PropTypes.string,
  url: PropTypes.string,
  outrightsData: PropTypes.object,
  category: PropTypes.shape({
    length: PropTypes.number,
  }),
  noEventData: PropTypes.array,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
};

export default EventsOutrightsReactApp;
