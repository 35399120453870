import {
  ButtonDiv,
  TeamName,
  Team,
  EventsRow,
  ModifiedViewMoreBar,
} from 'CORE__UI/apps/EventsOutrightsApp/core__eventsOutrightsTableBodySection';
import styled from 'styled-components';
import { BK_COLORS } from 'UI/globals/colours';
const ButtonDiv_Q = styled(ButtonDiv)`
  button {
    margin-bottom: 0;
  }
`;
const EventsRow_Q = styled(EventsRow)`
  border-color: transparent;
`;
const ModifiedViewMoreBar_Q = styled(ModifiedViewMoreBar)`
  border-top: 0;
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
  margin-bottom: 0;
`;
export {
  ButtonDiv_Q as ButtonDiv,
  TeamName,
  Team,
  EventsRow_Q as EventsRow,
  ModifiedViewMoreBar_Q as ModifiedViewMoreBar,
};
