import {
  SOB,
  NAB,
  SS,
  OD,
  OU,
  CS,
  CSCnt,
  LS,
} from 'CORE__UI/buttons/OddsButton/core__oddsButtonStyles';
import styled, { css } from 'styled-components';
import { GREYS, FONT, FUNCTIONAL, BK_COLORS } from '../globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const LS_QN = styled(LS)`
  color: ${({ inactive }) => (inactive ? GREYS.silver : GREYS.black)};
  @media (max-width: 420px) {
    font-size: 11px;
  }
`;
const SOB_QN = styled(SOB)`
  background-color: ${BK_COLORS?.aliceBlue || GREYS.white};
  color: ${GREYS.black};
  ${({ inactive }) =>
    !inactive
      ? css`
          box-shadow: none;
          border: 1px solid
            ${({ selected }) =>
              selected
                ? `${BK_COLORS?.blue || FUNCTIONAL.positiveL}`
                : 'transparent'};
          background: ${({ selected }) =>
            selected &&
            `${BK_COLORS?.EVENTS.gradient || FUNCTIONAL.positiveL}`};
          &:hover {
            //background-color: ${GREYS.doveL};
            border: 1px solid
              ${({ selected }) =>
                selected
                  ? `${BK_COLORS?.blue || FUNCTIONAL.positiveL}`
                  : BK_COLORS?.blue};
            box-shadow: none;
          }
          @media (max-width: ${BREAKPOINT_S}) {
            &:hover {
              background-color: ${({ selected }) =>
                selected && `${FUNCTIONAL.positiveL}`};
              border: ${({ imageurl }) =>
                !imageurl && `1px solid ${BK_COLORS?.blue || FONT.primary}`};
              box-shadow: none;
            }
          }
          & > span {
            color: ${({ selected }) =>
              selected ? BK_COLORS?.darkblue : BK_COLORS?.blue};
          }
        `
      : css`
          background-color: ${GREYS.doveL};
          border: 1px solid ${FONT.light};
          color: ${GREYS.silver};
          &:hover {
            background-position: center;
          }
        `}
  height: 48px;
  @media (max-width: 420px) {
    min-height: 40px;
    height: 40px !important;
  }
`;
const NAB_QN = styled(NAB)`
  background-color: ${GREYS.doveL};
  color: ${GREYS.silverD};
  height: 48px;
  @media (max-width: 420px) {
    font-size: 11px;
  }
`;

const CS_QN = styled(CS)`
  color: ${BK_COLORS?.blue};
`;

export {
  SOB_QN as SOB,
  NAB_QN as NAB,
  SS,
  OD,
  OU,
  CS_QN as CS,
  CSCnt,
  LS_QN as LS,
};
