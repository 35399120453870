import { EAr } from 'CORE__UI/globals/ExpandArrow/core__expandArrowStyles';

import styled, { css } from 'styled-components';
import { SUPPLEMENTARY, FONT } from 'UI/globals/colours';

export const EAR = styled(EAr)`
  &:after {
    border-color: ${FONT.dark};
  }
  ${({ theme }) =>
    theme === 'dark' &&
    css`
      &:after {
        border-color: ${FONT.lightMute};
      }
      &:hover {
        &::after {
          border-color: ${SUPPLEMENTARY.casino};
        }
      }
    `}
`;

export { EAR as EAr };
