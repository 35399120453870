import {
  League,
  MarketRefLabel,
  MarketWagerLabel,
  MarketRefLabelContainer,
  EventMarketEmpty,
} from 'CORE__UI/apps/EventsApp/core__eventsTableBodySection';

import styled from 'styled-components';
import { BK_COLORS, GREYS, FONT } from '../../globals/colours';

const League_Q = styled(League)`
  color: ${GREYS.black};
  border-color: ${BK_COLORS.aliceBlue10};
  background-color: ${BK_COLORS?.EVENTS.bodySection || GREYS.doveL};
  min-height: 40px;
  padding: 12px 16px;
  line-height: 16px;
  & > span:after {
    border-color: ${BK_COLORS.aliceBlue10};
  }
  @media (max-width: 420px) {
    min-height: 32px;
    padding: 8px 12px;
    font-size: 11px;
  }
`;

const MarketRefLabelContainer_Q = styled(MarketRefLabelContainer)`
  border-color: ${BK_COLORS.aliceBlue10};
`;

const MarketRefLabel_Q = styled(MarketRefLabel)`
  color: ${GREYS.black};
`;

const MarketWagerLabel_Q = styled(MarketWagerLabel)`
  color: ${FONT.black.mid};
`;

export {
  League_Q as League,
  MarketRefLabel_Q as MarketRefLabel,
  MarketWagerLabel_Q as MarketWagerLabel,
  MarketRefLabelContainer_Q as MarketRefLabelContainer,
  EventMarketEmpty,
};
