import {
  ViewMoreBar,
  ViewMoreLabel,
} from 'CORE__UI/apps/EventsApp/core__eventsTableBody';
import styled from 'styled-components';
import { GREYS, BK_COLORS } from '../../globals/colours';
const ViewMoreBar_Q = styled(ViewMoreBar)`
  border-color: ${BK_COLORS.aliceBlue10};
  border-top: 0;
  border-bottom: 0;
  color: ${GREYS.black};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
`;
export { ViewMoreBar_Q as ViewMoreBar, ViewMoreLabel };
