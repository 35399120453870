import styled from 'styled-components';
import {
  EventsCell,
  StyledOddsButton,
} from 'CORE__UI/apps/EventsApp/core__market';

const EventsCell_EX = styled(EventsCell)`
  border: unset;
  margin-right: 4px;
  padding-right: 4px;
`;

const StyledOddsButton_EX = styled(StyledOddsButton)`
  margin: 0px 4px 8px 0px;
`;

export { EventsCell_EX as EventsCell, StyledOddsButton_EX as StyledOddsButton };
